import React from 'react';

import { PageProps } from 'gatsby';
import Episode, { Kind } from '../components/Episode';

import Layout from '../components/Layout';
import SEO from '../components/seo';

export type PostProps = PageProps & {
  pageContext: {
    frontmatter: { [k: string]: string };
    fields: { [k: string]: string };
    body: string;
  };
};

const Page: React.FC<PostProps> = (props: PostProps) => {
  const { pageContext } = props;

  return (
    <Layout>
      <SEO title={pageContext.frontmatter.title} />
      <Episode
        kind={Kind.Full}
        title={pageContext.frontmatter.title}
        {...pageContext}
        {...pageContext.frontmatter}
        {...pageContext.fields}
      />
    </Layout>
  );
};

export default Page;
